import React from 'react';

import './ContactPage.scss';

const ContactPage = () => {
  return (
    <div className="ContactPage">
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Kontakt</h2>
            </div>
            <div className="col-12">
              <a href="/">Hem</a>
              <a href="/">Kontakt</a>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="ContactHolder">
          <div className="InfoHolder">
            <h2>Let's get in touch</h2>
            <span className="infoContainer">
              <i className="far fa-envelope"></i>            
              <span>gladanac.a@gmail.com</span>
            </span>
            <span className="infoContainer">
              <i className="fas fa-mobile-alt"></i>
              <span>0739605170</span>
            </span>
            <div className="SocialMediaHolder">
              <span><a href="https://www.facebook.com/AlexGlad_HairCare-103173041965929" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a></span>
              <span><a href="https://www.instagram.com/alexglad_haircare/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a></span>
            </div>
          </div>
          <div className="MapHolder">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2035.0750172237113!2d18.045444077515086!3d59.33170137461544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x465f9d6237160b9b%3A0xabe9394aba0bb42c!2sKungsgatan%2084%2C%20112%2027%20Stockholm%2C%20Sweden!5e0!3m2!1sen!2srs!4v1725280078909!5m2!1sen!2srs"
                width="600"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="alexglad"
              />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
